<template>
  <div class="article-container">
    <el-card class="filter-card">
      <!-- 数据筛选表单 -->
      <el-form ref="form" label-width="80px" size="mini">
        <div class="flexparents">
          开始日期:
          <el-date-picker
            value-format="yyyy-MM-dd 00:00:00"
            v-model="form.createTimeAfter"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>
          结束日期:
          <el-date-picker
            value-format="yyyy-MM-dd 23:59:59"
            v-model="form.creatTimeBefore"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>

          <el-button
            class="leftgap"
            type="primary"
            :disabled="loading"
            @click="cleardata()"
            >清空</el-button
          >
          <el-button type="primary" :disabled="loading" @click="loadArticles(1)"
            >查询</el-button
          >
          <el-button
            type="info"
            :disabled="loading"
            @click="ifgaoji = !ifgaoji"
            >{{ ifgaoji ? "收起" : "高级查询" }}</el-button
          >
          <div v-if="ifgaoji" class="flexparents zdhh">
            <div>
              单号:
              <el-input
                class="input rightgap"
                v-model="form.workOrderNo"
                placeholder="请输入单号"
              ></el-input>
            </div>
            <div>
              国家:
              <el-input
                class="input rightgap"
                v-model="form.country"
                placeholder="请输入国家"
              ></el-input>
            </div>
            <div>
              购买渠道:
              <el-input
                class="input rightgap"
                v-model="form.channel"
                placeholder="请输入国家"
              ></el-input>
            </div>
            <div>
              描述:
              <el-input
                class="input rightgap"
                v-model="form.description"
                placeholder="请输入描述"
              ></el-input>
            </div>
            <div>
              邮箱:
              <el-input
                class="input rightgap"
                v-model="form.email"
                placeholder="请输入email"
              ></el-input>
            </div>
            <div>
              imei:
              <el-input
                class="input rightgap"
                v-model="form.imei"
                placeholder="请输入imei"
              ></el-input>
            </div>
            <div>
              当前站点:
              <el-select
                class="flexparents rightgap"
                v-model="form.stations"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in stationsoption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              是否转出:
              <el-select
                class="flexparents rightgap"
                v-model="form.transformFlag"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in transformFlagoption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <div>
              问题类型:
              <el-select
                class="flexparents rightgap"
                v-model="form.type"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in typeoption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        根据筛选条件共查询到 {{ totalCount }} 条结果：
      </div>
      <el-table
        :data="articles"
        stripe
        :height="tableHeight"
        class="list-table"
        size="mini"
        v-loading="loading"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="orderNo" label="工单号"> </el-table-column>
        <el-table-column label="问题状态">
          <template slot-scope="scope">
            <el-tag :type="processStatus[scope.row.process].type">{{
              processStatus[scope.row.process].text
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="orderNum" label="购买订单号"> </el-table-column>
        <el-table-column prop="email" label="邮箱"> </el-table-column>
        <el-table-column prop="channel" label="购买渠道"> </el-table-column>
        <el-table-column prop="followPersonName" label="跟进人">
        </el-table-column>

        <el-table-column prop="country" label="国家"> </el-table-column>
        <el-table-column prop="deviceModel" label="设备型号"> </el-table-column>
        <el-table-column prop="imei" label="IMEI"> </el-table-column>
        <el-table-column prop="platform" label="平台/APP"> </el-table-column>
        <el-table-column prop="description" width="350" label="描述">
        </el-table-column>
        <el-table-column label="使用场景">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.usageScenario != null"
              :type="usageScenarioStatus[scope.row.usageScenario].type"
              >{{ usageScenarioStatus[scope.row.usageScenario].text }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="当前站点">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="progressStatus[scope.row.station].type">{{
              progressStatus[scope.row.station].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="createdTime" label="创建时间"> </el-table-column>
        <el-table-column prop="completedByName" label="完成人">
        </el-table-column>
        <el-table-column prop="faeOperatorName" label="FAE操作员">
        </el-table-column>
        <el-table-column width="220" label="操作" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-tooltip
                content="查看详情"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="getdetaile(scope.row.workOrderId)"
                  circle
                  icon="el-icon-search"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                v-if="scope.row.process != 3"
                content="完成"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="finish(scope.row)"
                  circle
                  icon="el-icon-close"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
               v-if="scope.row.process == 5"
                content="重新打开FAE"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="reopen(scope.row)"
                  circle
                  icon="el-icon-refresh"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                content="转交FAE"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="giveFae(scope.row.workOrderId)"
                  circle
                  icon="el-icon-s-custom"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                content="更改跟进人"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="changeFelloMan(scope.row.workOrderId)"
                  circle
                  icon="el-icon-user"
                  type="primary"
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="sizes,prev, pager, next"
        background
        :total="totalCount"
        :page-sizes="[10, 20, 50, 100]"
        @size-change="handleSizeChange"
        :page-size="form.size"
        :disabled="loading"
        :current-page.sync="form.page"
        @current-change="onCurrentChange"
      />
      <!-- /列表分页 -->
    </el-card>

    <el-dialog
      title="工单详情:"
      modal-append-to-body
      :visible.sync="showOrder"
      width="1100px"
    >
    <el-steps
        :active="steptype"
        finish-status="success"
        class="gap"
        style="padding-left: 16px; padding-right: 16px"
      >
        <el-step
          title="开始"
          :description="esservedatiledata.createdTime"
        ></el-step>
        <el-step
          title="处理中"
          :description="esservedatiledata.createdTime"
        ></el-step>
        <el-step
          title="转至FAE"
          :description="FAEdatile.createdTime"
        ></el-step>
        <el-step
          title="FAE处理中"
          :description="FAEdatile.firstBrowseTime"
        ></el-step>
        <el-step
          title="FAE处理完成"
          :description="FAEdatile.completedTime"
        ></el-step>
        <el-step
          title="待确认完成"
          :description="FAEdatile.completedTime"
        ></el-step>
        <el-step
          title="完成"
          :description="esservedatiledata.completedTime"
        ></el-step>
      </el-steps>

      <el-descriptions
        class="desbasebody"
        :column="3"
        style="margin-bottom: 2rem"
      >
        <el-descriptions-item label="订单号">{{
          esservedatiledata.orderNo
        }}</el-descriptions-item>
        <el-descriptions-item label="问题类型">{{
          esservedatiledata.type
        }}</el-descriptions-item>
        <el-descriptions-item label="跟进人">{{
          esservedatiledata.followPersonName
        }}</el-descriptions-item>
        <el-descriptions-item label="IMEI">{{
          esservedatiledata.imei
        }}</el-descriptions-item>
        <el-descriptions-item label="设备状态">{{
          esservedatiledata.deviceStatus
        }}</el-descriptions-item>
        <el-descriptions-item label="设备LED灯状态">{{
          esservedatiledata.ledStatus
        }}</el-descriptions-item>
        <el-descriptions-item label="型号">{{
          esservedatiledata.deviceModel
        }}</el-descriptions-item>
        <el-descriptions-item label="平台/APP">{{
          esservedatiledata.platform
        }}</el-descriptions-item>
        <el-descriptions-item label="SIM卡运营商">{{
          esservedatiledata.simCardOperator
        }}</el-descriptions-item>
        <el-descriptions-item label="ICCID">{{
          esservedatiledata.iccid
        }}</el-descriptions-item>
        <el-descriptions-item label="使用场景">{{
          esservedatiledata.usageScenario
        }}</el-descriptions-item>
        <el-descriptions-item label="安装车型号">{{
          esservedatiledata.installedVehicleModel
        }}</el-descriptions-item>
        <el-descriptions-item label="Email">{{
          esservedatiledata.email
        }}</el-descriptions-item>
        <el-descriptions-item label="其他联系方式">{{
          esservedatiledata.otherContactInfo
        }}</el-descriptions-item>
        <el-descriptions-item label="FAE操作员">{{
          FAEdatile.faeOperatorName
        }}</el-descriptions-item>
        <el-descriptions-item label="购买渠道">{{
          esservedatiledata.channel
        }}</el-descriptions-item>
        <el-descriptions-item label="国家">{{
          esservedatiledata.country
        }}</el-descriptions-item>
        <el-descriptions-item label="购买订单号">{{
          esservedatiledata.orderNum
        }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
          esservedatiledata.createdTime
        }}</el-descriptions-item>
        <el-descriptions-item label="完成时间">{{
          esservedatiledata.completedTime
        }}</el-descriptions-item>
      </el-descriptions>

      <div class="desbody" v-if="this.esservedatiledata.orderNo">
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item label="问题描述" :span="3">
            <p style="white-space: pre-line">
              {{ esservedatiledata.description }}
            </p>
          </el-descriptions-item>
          <el-descriptions-item :span="4" label="问题进度">{{
            esservedatiledata.solution
          }}</el-descriptions-item>
          <el-descriptions-item :span="4" label="备注">{{
            esservedatiledata.remark
          }}</el-descriptions-item>

          <el-descriptions-item label="图片" :span="16">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              v-for="{ url } in esservedatiledata.image"
              :key="url"
              :src="url"
              :previewSrcList="ESpreviewList"
            />
          </el-descriptions-item>
          <el-descriptions-item label="附件" :span="16">
            <p v-for="{ url, path } in esservedatiledata.attachment" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="openeidtdig()">编辑</el-button>
        <el-button type="primary" @click="showOrder = false">关闭</el-button>
      </span>
      <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="编辑"
        :visible.sync="innerVisible"
        append-to-body
      >
        <el-form>
          <el-form-item label="设备型号">
            <el-input v-model="editform.deviceModel"></el-input>
          </el-form-item>
          <el-form-item label="IMEI号">
            <el-input v-model="editform.imei"></el-input>
          </el-form-item>
          <el-form-item label="ICCID">
            <el-input v-model="editform.iccid"></el-input>
          </el-form-item>
          <el-form-item label="SIM卡运营商">
            <el-input v-model="editform.simCardOperator"></el-input>
          </el-form-item>
          <el-form-item label="问题描述">
            <el-input type="textarea" v-model="editform.description"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="editform.remark"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="editesserve">编辑信息</el-button>
            <el-button @click="(innerVisible = false), (showOrder = false)"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-dialog>

    <el-dialog title="重新打开FAE" :visible.sync="showReopen">
      <div>
        是否确认重新打开FAE
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showSolveDio = false">取 消</el-button>
        <el-button type="primary" @click="DoReOpen()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="解决工单" :visible.sync="showSolveDio">
      <el-form :model="EsOrderSolveform">
        <el-form-item label="问题进度">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3 }"
            v-model="EsOrderSolveform.solution"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3 }"
            v-model="EsOrderSolveform.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showSolveDio = false">取 消</el-button>
        <el-button type="primary" @click="setEsOrderSolve()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="分配FAE操作员" :visible.sync="showAssignFaeOperator">
      <el-select v-model="setFaeManForm.faeOperatorName" placeholder="请选择">
        <el-option
          v-for="item in FAElist"
          :key="item.userId"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showAssignFaeOperator = false">取 消</el-button>
        <el-button type="primary" @click="RunFaeOperator">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="更改跟进人" :visible.sync="showChangeES">
      <el-select
        v-model="changefollowForm.followPersonName"
        placeholder="请选择"
      >
        <el-option
          v-for="item in ECSaleslist"
          :key="item.userId"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showChangeES = false">取 消</el-button>
        <el-button type="primary" @click="RunChangeEsOperator">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getESpageUrl,
  getOneESorderUrl,
  EsOrderSolveOrderUrl,
  transformFaeOperatorUrl,
  EsOrderEditUrl,
  getECSalesListUrl,
  transformFollowPersonUrl,
  DoReOpenUrl
} from "@/api/esserve";

import { getFaeOperatorListUrl } from "@/api/fae";

import {
  progressStatus
} from "@/options/index";

export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      progressStatus,

      steptype:0,
      editform: {
        workOrderId: null,
        deviceModel: null,
        imei: null,
        iccid: null,
        simCardOperator: null,
        description: null,
        remark: null,
      },
      reopenform: {},
      innerVisible: false,
      showAssignFaeOperator: false,
      showChangeES: false,
      FAElist: [],
      ECSaleslist: [],
      showSolveDio: false,
      showReopen: false,
      previewList: [],
      ESpreviewList: [],
      tableHeight: window.innerHeight - 320,
      loading: true, // 表单数据加载中 loading
      articles: [], // 文章数据列表
      totalCount: 0, // 总数据条数
      ifgaoji: false,
      form: {
        ownerType: 5,
        createdByStations: 5,
        creatTimeBefore: null,
        createTimeAfter: null,
        size: 20, // 每页大小
        page: 1, // 当前页码

        workOrderNo: null,
        description: null,
        email: null,
        imei: null,
        country: null,

        stations: null,
        type: null,
        usageScenario: null,
        channel: null,
        transformFlag: null,
      },
      setFaeManForm: {
        workOrderId: null,
        faeOperatorName: null,
      },
      changefollowForm: {
        workOrderId: null,
        followPersonName: null,
      },
      EsOrderSolveform: {
        workOrderId: null,
        solution: null,
        remark: null,
      },

      stationsoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 5,
          label: "电商客户服务",
        },
        {
          value: 2,
          label: "FAE",
        },
        {
          value: 3,
          label: "研发",
        },
      ],
      transformFlagoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      typeoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "0",
          label: "Device Inactive",
        },
        {
          value: "1",
          label: "Device Offline",
        },
        {
          value: "2",
          label: "IMEI Not Existed",
        },
        {
          value: "3",
          label: "I already paid, still not working",
        },
        {
          value: "4",
          label: "Wrong location",
        },
        {
          value: "5",
          label: "About Alert",
        },
        {
          value: "6",
          label: "About Subscription",
        },
        {
          value: "7",
          label: "About Battery / Charging",
        },
      ],
      usageScenariooption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "0",
          label: "Vehicle",
        },
        {
          value: "1",
          label: "Personal",
        },
        {
          value: "2",
          label: "Asset",
        },
        {
          value: "3",
          label: "Other",
        },
      ],
      processStatus: [
        { status: 0, text: "开启", type: "primary" },
        { status: 1, text: "完成", type: "success" },
        { status: 2, text: "待评价", type: "info" },
        { status: 3, text: "已转出", type: "warning" },
        { status: 4, text: "已完成", type: "warning" },
        { status: 5, text: "待完成", type: "info" },
      ],

      usageScenarioStatus: [
        { status: 0, text: "Vehicle", type: "info" },
        { status: 1, text: "Personal", type: "info" },
        { status: 2, text: "Asset", type: "info" },
        { status: 3, text: "Other", type: "info" },
      ],
    
      esservedatiledata: {},
      FAEdatile: {},
      yanfadatile: {},
      showOrder: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.form.workOrderNo = this.$route.query.orderNo;
    this.getFaeOperatorList();
    this.getECSalesList();
    window.addEventListener("resize", this.getTableHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.getTableHeight);
  },
  mounted() {
    this.loadArticles(1);
  },
  methods: {
    openeidtdig() {
      this.editform.workOrderId = this.esservedatiledata.workOrderId;
      this.editform.deviceModel = this.esservedatiledata.deviceModel;
      this.editform.imei = this.esservedatiledata.imei;
      this.editform.iccid = this.esservedatiledata.iccid;
      this.editform.simCardOperator = this.esservedatiledata.simCardOperator;
      this.editform.description = this.esservedatiledata.description;
      this.editform.remark = this.esservedatiledata.remark;
      this.innerVisible = true;
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.process == 1) {
        return "complate-row";
      } else {
        return "warning-row";
      }
    },

    async editesserve() {
      try {
        const res = await EsOrderEditUrl(this.editform);
        if (res.data.code === 200) {
          this.$message({
            message: "编译成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        // innerVisible = false), (showOrder = false
        this.innerVisible = false;
        this.showOrder = false;
        this.loadArticles();
      }
    },
    async RunFaeOperator() {
      try {
        const res = await transformFaeOperatorUrl(this.setFaeManForm);
        if (res.data.code === 200) {
          this.$message({
            message: "分配FAE成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showAssignFaeOperator = false;
        (this.setFaeManForm = {
          workOrderId: null,
          faeOperatorName: null,
        }),
        this.loadArticles();
      }
    },
    giveFae(row, column, event) {
      this.setFaeManForm.workOrderId = row;
      this.showAssignFaeOperator = true;
    },
    changeFelloMan(row, column, event) {
      this.changefollowForm.workOrderId = row;
      this.showChangeES = true;
    },
    async RunChangeEsOperator() {
      try {
        const res = await transformFollowPersonUrl(this.changefollowForm);
        if (res.data.code === 200) {
          this.$message({
            message: "更改跟进人成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showChangeES = false;
        (this.changefollowForm = {
          workOrderId: null,
          followPersonName: null,
        }),
          this.loadArticles();
      }
    },

    async getECSalesList() {
      try {
        const res = await getECSalesListUrl();
        if (res.data.code === 200) {
          this.ECSaleslist = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },

    async getFaeOperatorList() {
      try {
        const res = await getFaeOperatorListUrl();
        if (res.data.code === 200) {
          this.FAElist = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },
    finish(row, column, event) {
      this.EsOrderSolveform = {
        workOrderId: row.workOrderId,
        solution: row.solution,
        remark: row.remark,
      };
      this.showSolveDio = true;
    },
    reopen(row, column, event) {
      this.reopenform = {
        workOrderId: row.workOrderId,
      };
      this.showReopen = true;
    },
    
    async DoReOpen() {
      try {
        const res = await DoReOpenUrl(this.reopenform);
        if (res.data.code === 200) {
          this.$message({
            message: "重新打开FAE成功",
            type: "success",
          });
         
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loadArticles();
        this.showReopen = false;
      }
    },

    async setEsOrderSolve() {
      try {
        const res = await EsOrderSolveOrderUrl(this.EsOrderSolveform);
        if (res.data.code === 200) {
          this.$message({
            message: "此工单已完成",
            type: "success",
          });
          this.loadArticles(1);
          this.planVisable = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showSolveDio = false;
      }
    },
    async getdetaile(id) {
      this.FAEdatile = {}
      this.esservedatiledata = {};
      try {
        const res = await getOneESorderUrl({
          workOrderId: id,
        });
        if (res.data.code === 200) {
          this.FAEdatile = res.data.data?.technicalSupportWorkOrderVo || {};
          this.esservedatiledata = {
            ...res.data.data.ecServeClientWorkOrderVo,
            attachment: (
              res.data.data.ecServeClientWorkOrderVo.fileInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
            image: (
              res.data.data.ecServeClientWorkOrderVo.imageInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
          };
          // 预览图片列表
          this.previewList = (
            res.data.data.ecServeClientWorkOrderVo.imageInfos || []
          ).map(({ url }) => url);

          if (this.esservedatiledata.type == 0) {
            this.esservedatiledata.type = "Device Inactive";
          } else if (this.esservedatiledata.type == 1) {
            this.esservedatiledata.type = "Device Offline";
          } else if (this.esservedatiledata.type == 2) {
            this.esservedatiledata.type = "IMEI Not Existed";
          } else if (this.esservedatiledata.type == 3) {
            this.esservedatiledata.type = "I already paid, still not working";
          } else if (this.esservedatiledata.type == 4) {
            this.esservedatiledata.type = "Wrong location";
          } else if (this.esservedatiledata.type == 5) {
            this.esservedatiledata.type = "About Alert";
          } else if (this.esservedatiledata.type == 6) {
            this.esservedatiledata.type = "About Subscription";
          } else if (this.esservedatiledata.type == 7) {
            this.esservedatiledata.type = "About Battery / Charging";
          }

          if (this.esservedatiledata.usageScenario == 0) {
            this.esservedatiledata.usageScenario = "Vehicle";
          } else if (this.esservedatiledata.usageScenario == 1) {
            this.esservedatiledata.usageScenario = "Personal";
          } else if (this.esservedatiledata.usageScenario == 2) {
            this.esservedatiledata.usageScenario = "Asset";
          } else if (this.esservedatiledata.usageScenario == 3) {
            this.esservedatiledata.usageScenario = "Other";
          }

          this.ESpreviewList = (
            res.data.data.ecServeClientWorkOrderVo.imageInfos || []
          ).map(({ url }) => url);

          this.showOrder = true;

          if (res.data.data.ecServeClientWorkOrderVo.process == 0) {
            this.steptype = 1;
          } else if (res.data.data.ecServeClientWorkOrderVo.process == 3) {
            this.steptype = 3;
          } else if (res.data.data.ecServeClientWorkOrderVo.process == 5) {
            this.steptype = 5;
          } else if (res.data.data.ecServeClientWorkOrderVo.process == 1) {
            this.steptype = 7;
          } else {
            this.steptype = 5;
          }
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    getTableHeight() {
      this.tableHeight = window.innerHeight - 320;
    },
    handleSizeChange(val) {
      this.form.size = val;
      this.loadArticles();
    },
    onCurrentChange(page) {
      this.loadArticles(page);
    },
    async loadArticles(page = 1) {
      this.loading = true;
      try {
        const res = await getESpageUrl(this.form);
        if (res.data.code === 200) {
          this.articles = res.data.data.record;
          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }

      // });
    },
  },
};
</script>

<style scoped lang="less">
.header {
  float: right;
}

.article-container {
  height: 100%;
}

.list-table {
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.input {
  width: 200px;
  margin-right: 10px;
}
.filter-card {
  margin-bottom: 5px;
}
.flexparents {
  margin-bottom: 5px;
}
.el-select {
  width: 150px;
}
.leftgap {
  margin-left: 20px;
}
.rightgap {
  margin-right: 20px;
}

.zdhh {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.floter {
  display: flex;
  justify-content: space-between;
}
/deep/.el-table .warning-row {
  background: oldlace;
  font-weight: bold;
  font-size: 12px;
}
/deep/.el-table .complate-row {
  background: #ffffff;
}
/deep/.el-table .white-row {
  background: #ffffff;
  font-weight: bold;
  font-size: 12px;
}

.destitle {
  padding-left: 18px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

/deep/.el-descriptions-item__label {
  font-weight: bold !important;
  color: rgb(0, 0, 0);
}

.desbasebody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
  background-color: #ecf4fd;
  /deep/.el-descriptions__table {
    background-color: #ecf4fd;
  }
}
.desbody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
}
</style>
